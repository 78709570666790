<template>
  <v-card class="mt-6" elevation="0">
    <v-row dense>
      <v-col cols="12">
        <glitch color="#000" background="#fff" class="text-center" text="There was a problem with your scan."></glitch><br />

        <v-alert type="warning"> {{ errorMessage }}</v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Glitch from 'vue-glitch';

export default {
  name: 'ReportingNoPagesCrawled',
  props: {
    // eslint-disable-next-line no-undef
    statusMessage: Object,
    statusMessageType: String,
    statusMessageFormat: String,
  },
  components: { Glitch },
  computed: {
    errorMessage() {
      if (this.statusMessage && this.statusMessage.wrap && this.statusMessageFormat === 'string') {
        return this.statusMessage.wrap;
      }

      if (this.statusMessageFormat === 'object' && this.statusMessage.wrap.statusCode === 600) {
        return `The url you tried to crawl redirected to this domain: ${this.statusMessage.wrap.redirectedTo}. The crawler does not follow redirects to different domains. Please try it again with the correct domain.`;
      }

      if (this.statusMessageFormat === 'object' && this.statusMessage.wrap.statusCode > 308) {
        return `The url you tried to crawl returned a http status code: ${this.statusMessage.wrap.statusCode}. So the crawler was not able to crawl this domain.`;
      }

      return `There are a lot of reasons possible, the most common are: <br />The url responded with a non-200er http response, the
          url could not be found or there was a redirect to another domain.`;
    },
  },
};
</script>
<style>
.glitch {
  font-size: 1.4rem !important;
  padding: 0.5em;
  font-family: 'Roboto', sans-serif !important;
}

.glitch:after {
  background-color: #fb8c00 !important;
}
.glitch:before {
  background-color: #fb8c00 !important;
}
</style>
