<template>
  <v-card class="px-4 py-8">
    <v-row dense>
      <v-col :cols="colSpanDefault" :sm="colSpanSM" :md="colSpanMD" :lg="colSpanLG" :xl="colSpanXL">
        <div class="d-flex justify-center align-center">
          <v-icon :color="`${colorBanner}`" x-large @click="emitSetSummaryMessage()" style="cursor: pointer">
            {{ iconBanner }}
          </v-icon>
        </div>
        <div
          class="d-flex justify-center align-center text-center text-subtitle-1 ma-3"
          @click="emitSetSummaryMessage()"
          style="cursor: pointer"
        >
          {{ headlineBannerFound }}
        </div>
      </v-col>
      <v-col :cols="colSpanDefault" :sm="colSpanSM" :md="colSpanMD" :lg="colSpanLG" :xl="colSpanXL">
        <div class="d-flex justify-center align-center">
          <v-icon x-large :color="`${colorRequest}`" @click="emitSetSummaryMessage()" style="cursor: pointer">
            {{ iconRequest }}
          </v-icon>
        </div>
        <div
          class="d-flex justify-center align-center text-center text-subtitle-1 ma-3"
          @click="emitSetSummaryMessage()"
          style="cursor: pointer"
        >
          {{ headlineThirdPartyTracking }}
        </div>
      </v-col>
      <v-col :cols="colSpanDefault" :sm="colSpanSM" :md="colSpanMD" :lg="colSpanLG" :xl="colSpanXL">
        <div class="d-flex justify-center align-center">
          <v-icon x-large :color="`${colorStorage}`" @click="emitSetSummaryMessage()" style="cursor: pointer">
            {{ iconStorage }}
          </v-icon>
        </div>
        <div
          class="d-flex justify-center align-center text-center text-subtitle-1 ma-3"
          @click="emitSetSummaryMessage()"
          style="cursor: pointer"
        >
          {{ headlineStorage }}
        </div>
      </v-col>
      <v-col v-if="colorDupes === 'error'" :cols="colSpanDefault" :sm="colSpanSM" :md="colSpanMD" :lg="colSpanLG" :xl="colSpanXL">
        <div class="d-flex justify-center align-center">
          <v-icon x-large :color="`${colorDupes}`">
            {{ iconDupes }}
          </v-icon>
        </div>
        <div class="d-flex justify-center text-center align-center text-subtitle-1 ma-3">
          {{ headlineDupes }}
        </div>
        <p class="d-flex justify-center align-center text-center text-caption ma-3">
          Currently, only duplicate page views from Meta and GA4 are detected.
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiCheckDecagram, mdiHelpCircle, mdiAlert, mdiDecagram } from '@mdi/js';

export default {
  name: 'ResultEvaluation',
  props: {
    result: Object,
  },
  components: {},
  data: () => ({
    colSpanDefault: 12,
    colSpanSM: 12,
    colSpanMD: 4,
    colSpanLG: 4,
    colSpanXL: 4,
    colorStorage: 'green',
    iconStorage: mdiCheckDecagram,
    iconDupes: mdiCheckDecagram,
    colorBanner: 'green',
    iconBanner: mdiCheckDecagram,
    colorRequest: 'green',
    iconRequest: mdiCheckDecagram,
    iconGood: mdiCheckDecagram,
    iconWarn: mdiHelpCircle,
    iconBad: mdiAlert,
    iconNeutral: mdiDecagram,
  }),
  computed: {
    headlineBannerFound() {
      if (!this.result) {
        return '';
      }

      if (this.result.bannerNotFoundOnPages === -1 && (this.colorStorage === 'green' || this.colorRequest === 'green')) {
        this.colorBanner = 'green';
        this.iconBanner = this.iconNeutral;
        return 'Not checked for banner';
      }

      if (this.result.bannerNotFoundOnPages === -1 && (this.colorStorage !== 'green' || this.colorRequest !== 'green')) {
        this.colorBanner = 'default';
        this.iconBanner = this.iconNeutral;
        return 'Not checked for banner';
      }

      if (this.result.bannerNotFoundOnPages < 0 && (this.colorStorage === 'error' || this.colorRequest === 'error')) {
        this.colorBanner = 'error';
        this.iconBanner = this.iconBad;
        return 'No Banner installed, but 3rd Parties found';
      }

      if (this.result.bannerNotFoundOnPages < 0 && (this.colorStorage === 'warning' || this.colorRequest === 'warning')) {
        this.colorBanner = 'warning';
        this.iconBanner = this.iconWarn;
        return 'No Banner installed, but suspicious behaviour';
      }

      if (this.result.bannerNotFoundOnPages === 0) {
        return 'Banner found on all pages';
      }
      if (this.result.bannerNotFoundOnPages > 0) {
        this.colorBanner = 'error';
        this.iconBanner = this.iconBad;
        return 'Banner NOT found on all pages';
      }
      return '';
    },
    tracker() {
      const tracker = this.result.table.trackingStats.rowData.filter(row => !/Tag Manager/.test(row.type));
      return tracker;
    },
    headlineThirdPartyTracking() {
      if (!this.result || !this.result.table || !this.result.table.thirdPartyRequests) {
        return '';
      }
      const numberThirdParty = this.result.numberOfThirdPartyHostnames;
      const numberTracking = this.tracker.length;

      if (numberThirdParty <= 0 && numberTracking <= 0) {
        return 'No suspicious request found';
      }

      if (numberThirdParty > 0 && numberTracking <= 0) {
        this.colorRequest = 'warning';
        this.iconRequest = this.iconWarn;
        return 'No tags found, but 3rd Party requests';
      }

      if (numberThirdParty > 0 && numberTracking > 0) {
        this.colorRequest = 'error';
        this.iconRequest = this.iconBad;
        return 'Tags and 3rd Party requests found';
      }

      if (numberThirdParty <= 0 && numberTracking > 0) {
        this.colorRequest = 'warning';
        this.iconRequest = this.iconWarn;
        return 'First Party tags found';
      }
      return '';
    },
    headlineStorage() {
      if (
        !this.result ||
        !this.result.table ||
        !this.result.table.localStorage ||
        !this.result.table.cookies ||
        !this.result.table.cookies.metaData
      ) {
        return '';
      }
      const { numberOfCookies, numberOfLocalStorage, numberOfCookiesThirdParty } = this.result;
      const firstPartyStorage = numberOfCookies + numberOfLocalStorage - numberOfCookiesThirdParty;

      if (numberOfCookiesThirdParty <= 0 && firstPartyStorage <= 0) {
        return 'No storage used';
      }

      if (numberOfCookiesThirdParty <= 0 && firstPartyStorage > 0) {
        this.colorStorage = 'warning';
        this.iconStorage = this.iconWarn;
        return 'First Party storage used';
      }

      if (numberOfCookiesThirdParty > 0) {
        this.colorStorage = 'error';
        this.iconStorage = this.iconBad;
        return '3rd Party storage used';
      }
      return '';
    },
    colorDupes() {
      if (!this.result) {
        return 'green';
      }
      const { reportDupes } = this.result;
      if (reportDupes === true) {
        return 'error';
      }

      return 'warning';
    },
    headlineDupes() {
      if (!this.result) {
        return '';
      }
      const { reportDupes } = this.result;
      //
      if (reportDupes === true) {
        this.iconDupes = this.iconBad;
        this.colSpanDefault = 12;
        this.colSpanSM = 12;
        this.colSpanMD = 3;
        this.colSpanLG = 3;
        this.colSpanXL = 3;
        return 'Duplicate Tracking Events Detected';
      }

      return '';
    },
  },

  methods: {
    emitSetSummaryMessage() {
      this.$emit('message', { type: 'setSummary', value: true });
    },
  },
};
</script>
