<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="tableItems" />
</template>
<script>
import TableReporting from '../../TableReporting.vue';

export default {
  name: 'TrackingReporting',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data() {
    return {
      tableConfig: {
        sortBy: ['type'],
        sortDesc: [true],
        showGroupBy: true,
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: 'Tracking.',
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          { text: this.$t('tableHeadEventType'), value: 'requestType' },
          { text: this.$t('accountId'), value: 'accountId' },
          { text: this.$t('Type'), value: 'type' },
          { text: this.$t('searchString'), value: 'searchString' },
          { text: this.$t('No. of Request'), value: 'count' },
        ],
      },
    };
  },
  computed: {
    tableItems() {
      if (this.result && this.result.table) {
        return this.result.table.trackingStats.rowData;
      }
      return [];
    },
  },
  methods: {},
};
</script>
