var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":_vm.loadingText,"headers":_vm.internalHeaders,"items":_vm.internalTableItems,"items-per-page":_vm.itemsPerPage,"no-data-text":_vm.noDataText,"show-group-by":_vm.tableConfig.showGroupBy,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":_vm.showExpand,"item-key":"id","footer-props":{
    itemsPerPageOptions: [50, 100, -1],
  },"item-class":_vm.itemRowBackground},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.tableConfig.toggleTopShow)?{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":_vm.tableConfig.toggleTopLabel},model:{value:(_vm.toggleTopModel),callback:function ($$v) {_vm.toggleTopModel=$$v},expression:"toggleTopModel"}})]},proxy:true}:null,{key:"item.data-table-expand",fn:function(ref){
  var item = ref.item;
  var isExpanded = ref.isExpanded;
return [(isExpanded)?_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},[_vm._v("close")]):_vm._e(),(!isExpanded)?_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},[_vm._v("initiator")]):_vm._e()]}},{key:"item.value",fn:function(ref){
  var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.rowClicked(item.value)}}},[_vm._v(_vm._s(_vm.shortenValue(item.value)))]),(item.value && item.value.length > _vm.maxValueLength)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.copyToClipboard(item.value)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiContentCopy))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e()]}},{key:"item.name",fn:function(ref){
  var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.rowClicked(item.name)}}},[_vm._v(_vm._s(_vm.shortenValue(item.name)))]),(item.name && item.name.length > _vm.maxValueLength)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.copyToClipboard(item.name)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiContentCopy))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}},{key:"item.accountId",fn:function(ref){
  var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.rowClicked(item.accountId)}}},[_vm._v(_vm._s(_vm.shortenValue(item.accountId)))]),(item.accountId && item.accountId.length > _vm.maxValueLength)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.copyToClipboard(item.accountId)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiContentCopy))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.accountId))])]):_vm._e()]}},{key:"item.searchString",fn:function(ref){
  var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.rowClicked(item.searchString)}}},[_vm._v(_vm._s(_vm.shortenValue(item.searchString)))]),(item.searchString && item.searchString.length > _vm.maxValueLength)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.copyToClipboard(item.searchString)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiContentCopy))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.searchString))])]):_vm._e()]}},{key:"item.path",fn:function(ref){
  var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.rowClicked(item.path)}}},[_vm._v(_vm._s(_vm.shortenValue(item.path)))]),(item.path && item.path.length > _vm.maxValueLength)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.copyToClipboard(item.path)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiContentCopy))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.path))])]):_vm._e()]}},{key:"item.examplePagesNotFound",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',_vm._l((item.examplePagesNotFound),function(link,index){return _c('v-list-item-title',{key:index},[_c('a',{staticClass:"grey--text text--darken-1",attrs:{"href":link,"target":"_blank"}},[_vm._v(_vm._s(_vm.getDisplayLink(link)))])])}),1)],1)]}},{key:"item.examplePagesFoundDuplicates",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',_vm._l((item.examplePagesFoundDuplicates),function(link,index){return _c('v-list-item-title',{key:index},[_c('a',{staticClass:"grey--text text--darken-1",attrs:{"href":link,"target":"_blank"}},[_vm._v(_vm._s(_vm.getDisplayLink(link)))])])}),1)],1)]}},{key:"item.examplePagesFound",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',_vm._l((item.examplePagesFound),function(link,index){return _c('v-list-item-title',{key:index},[_c('a',{staticClass:"grey--text text--darken-1",attrs:{"href":link,"target":"_blank"}},[_vm._v(_vm._s(_vm.getDisplayLink(link)))])])}),1)],1)]}},{key:"item.timeCrawlStart",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.timeCrawlStart))+" ")]}},{key:"item.crawlTime",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.crawlTime))+" ")]}},{key:"item.crawledUrlShort",fn:function(ref){
  var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.crawledUrlShort)+" "),_c('a',{attrs:{"href":item['crawledUrl'],"target":"_blank"}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)])]}},{key:"item.showDetailsLink",fn:function(ref){
  var item = ref.item;
return [(item.NoOfCountDiffTotal > 0 || !_vm.tableConfig.toggleTopShow)?_c('td',{on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('a',[_vm._v("show details")])]):_vm._e(),(item.NoOfCountDiffTotal === 0 && _vm.tableConfig.toggleTopShow)?_c('td',[_vm._v("n.a.")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list-item',[_c('v-list-item-content',_vm._l((item.expandArea),function(link,index){return _c('v-list-item-title',{key:index},[_vm._v(" "+_vm._s(link))])}),1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }