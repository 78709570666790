<template>
  <span>
    <v-card v-if="detailsStillLoading" class="d-flex justify-center mb-6" flat tile>
      <v-progress-circular :width="10" color="primary" :size="100" indeterminate></v-progress-circular>
    </v-card>
    <v-chip v-if="chipUrl && !detailsStillLoading" class="ma-2" close @click:close="backToOverview()">
      {{ pageDetails.startUrl }}
    </v-chip>
    <OverviewReporting v-bind:result="pageDetails" v-if="!detailsStillLoading" />
  </span>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiDeathStarVariant, mdiAlienOutline, mdiFingerprint, mdiNas, mdiCookie, mdiCodeJson } from '@mdi/js';
import { getPageDetails } from '../../../utils/apiDetails';
import OverviewReporting from './pageDetailsReporting/OverviewReporting.vue';

export default {
  name: 'ReportingIndexPageDetails',
  props: {
    crawlRequestId: String,
    keyId: String,
  },
  components: {
    OverviewReporting,
  },
  data() {
    return {
      mdiDeathStarVariant,
      chipUrl: true,
      mdiAlienOutline,
      mdiFingerprint,
      mdiNas,
      mdiCookie,
      mdiCodeJson,
      tab: null,
      pageDetails: {},
      detailsStillLoading: true,
    };
  },
  async created() {
    const response = await getPageDetails(this.crawlRequestId, this.keyId);
    if (response.success === true) {
      this.pageDetails = response.data;
      this.detailsStillLoading = false;
      if (this.pageDetails.screenshotUrl) {
        this.$store.commit('summary/setScreenshotId', this.pageDetails.screenshotId);
      }
      return;
    }

    this.$store.commit('message/setMessage', { color: 'error', message: `${response.data.message}` });
    this.detailsStillLoading = false;
  },
  computed: {},
  methods: {
    backToOverview() {
      this.$router.push({
        name: 'SummaryReporting',
        params: { hostnameToCrawl: new URL(this.pageDetails.startUrl).hostname },
        query: { ucrid: this.crawlRequestId, pages: true },
      });
    },
  },
};
</script>
