<template>
  <v-card class="px-4 py-8">
    <v-row dense>
      <v-col cols="12" sm="5" md="5" lg="4" xl="4">
        <ScreenshotImage />
      </v-col>
      <v-col cols="12" sm="5" md="5" lg="6" xl="6">
        <v-row>
          <v-col>
            <div class="overline">
              {{ mainHeadline }}
              <strong>{{ result.startUrl }}</strong
              ><a :href="result.startUrl" target="_blank"
                ><v-icon dense>{{ mdiOpenInNew }}</v-icon></a
              >
            </div>
            <v-list-item-subtitle class="mt-2">
              {{ $t('reportDate') }}: {{ timeCrawlStart }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" x-small outlined color="secondary" v-bind="attrs" v-on="on" v-on:click="rescan">
                    <v-icon small>{{ mdiCached }}</v-icon>
                  </v-btn>
                </template>
                <span> {{ $t('summaryGetanewresult') }}</span>
              </v-tooltip>
            </v-list-item-subtitle></v-col
          ></v-row
        >
        <v-row>
          <v-col>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">{{ secondaryHeadline }} </v-list-item-title>
              <v-list-item-subtitle>{{ $t('pagesChecked') }}: {{ result.crawledPages }} </v-list-item-subtitle>
              <v-list-item-subtitle v-if="crawlRequestIdToCompare && changesDetected"
                >{{ $t('Changes detected') }}:
                {{
                  $t('{changesDetected} on {changesDetectedOnPages} distinct pages.', { changesDetected, changesDetectedOnPages })
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="crawlRequestIdToCompare && !changesDetected"
                >{{ $t('Changes detected') }}: {{ $t('none.') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="mt-2">{{ $t('duration') }}: {{ crawlDuration }}</v-list-item-subtitle>
              <v-list-item-subtitle
                >{{ $t('bannerName') }}: {{ bannerName }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-2" x-small outlined color="secondary" v-bind="attrs" v-on="on">
                      <v-icon small>{{ mdiInformationOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Is this result not correct? Please report it, using the feedback form at the button.</span>
                </v-tooltip></v-list-item-subtitle
              >
              <v-list-item-subtitle>{{ $t('bannerclicked') }}: {{ bannerAction }}</v-list-item-subtitle>
              <v-list-item-subtitle class="d-flex align-center">
                {{ $t('bannerNotFoundOnPages') }}: {{ bannerNotFoundOnPages }}
                <StatusIcon
                  v-if="bannerNotFoundOnPages > 0"
                  v-bind:status="`error`"
                  v-bind:statusMessage="`Is this result not correct? Please report it, using the feedback form at the button.`"
              /></v-list-item-subtitle>
              <v-list-item-subtitle v-if="crawlRequestIdToCompare"
                >{{ $t('comparedWith') }}:
                <a style="text-decoration: none" :href="`/summary/${starUrlHostName}/?ucrid=${crawlRequestIdToCompare}`"
                  >{{ $t('click here for original scan result') }}
                </a></v-list-item-subtitle
              >

              <v-list-item-subtitle v-if="loggedIn">
                <v-switch
                  v-model="shareableToggleTopModel"
                  :label="$t('Make it shareable (public)')"
                  class="pl-3"
                  @click="setShareableSettingsApi()"
                ></v-switch>
              </v-list-item-subtitle>
            </v-list-item-content> </v-col
        ></v-row>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2"
        ><v-row
          ><v-col>
            <v-btn width="100%" :small="$vuetify.breakpoint.mdAndDown" :href="VUE_APP_URL_HOME" color="primary" text>
              {{ $t('scananotherpage') }}</v-btn
            ></v-col
          ></v-row
        >
        <v-row
          ><v-col>
            <v-btn
              @click="
                $store.commit('dialog/setName', {
                  name: 'DialogScheduleATest',
                  data: { crawlRequestId, crawlerConfigs, summaryResult: result },
                })
              "
              width="100%"
              :small="$vuetify.breakpoint.mdAndDown"
              class="primary"
              >{{ $t('createAlert') }}</v-btn
            ></v-col
          ></v-row
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiCached, mdiOpenInNew, mdiInformationOutline } from '@mdi/js';
import ScreenshotImage from '../ScreenshotImage.vue';
import StatusIcon from '../StatusIcon.vue';
import { getFormattedDate, secondToMinute } from '../../utils/date';
import getBannerNameById from '../../utils/consentBannerConfigs';
import { setSharableSettings } from '../../utils/apiData';

export default {
  name: 'ReportingAccountIdsOverview',
  props: {
    result: Object,
    crawlRequestId: String,
  },
  components: {
    ScreenshotImage,
    StatusIcon,
  },
  data: () => ({
    showDialogScheduleATest: false,
    mdiCached,
    mdiOpenInNew,
    mdiInformationOutline,
    shareableToggleTopModel: false,
  }),
  mounted() {
    this.shareableToggleTopModel = this.result.isShareable;
  },
  computed: {
    locale() {
      const userLang = navigator.language || navigator.userLanguage;
      return userLang;
    },
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
    mainHeadline() {
      if (!this.result || !this.result.targetCrawler) {
        return 'loading...';
      }

      if (this.result.targetCrawler === 'dlMonitor') {
        return this.$t('summaryReportOverviewHeadlineDLMonitor');
      }

      return this.$t('summaryReportOverviewHeadline');
    },
    secondaryHeadline() {
      if (this.crawlRequestIdToCompare) {
        return this.$t('Comparison Summary');
      }
      return this.$t('summary');
    },
    bannerNotFoundOnPages() {
      if (!this.result || typeof this.result.bannerNotFoundOnPages === 'undefined') {
        return -2;
      }
      if (this.result.bannerNotFoundOnPages === -1) {
        return ' - ';
      }
      return this.result.bannerNotFoundOnPages;
    },
    bannerAction() {
      if (!this.result || !this.result.consentConfig) {
        return 'waiting for results...';
      }

      if (this.result.consentConfig.doTheConsentClick) {
        return 'yes';
      }

      return 'no';
    },
    bannerName() {
      if (!this.result || !this.result.consentConfig) {
        return 'waiting for results...';
      }

      // bannerId is legacy. keep just for old reports
      if (this.result.consentConfig.bannerId === 'noBanner' || this.result.consentConfig.checkForBanner === false) {
        return 'not checked for banners';
      }

      // legacy backward compatability
      if (this.result.consentConfig.bannerId === 'custom') {
        return this.result.consentConfig.selectorForConsentBannerClick;
      }

      const bannerId = this.result.consentConfig.bannerId ? this.result.consentConfig.bannerId : this.result.bannerIdsFound[0];

      return getBannerNameById(bannerId);
    },
    crawlDuration() {
      if (!this.result || !this.result.crawlDurationInSec) {
        return 'waiting for results...';
      }
      return secondToMinute(this.result.crawlDurationInSec);
    },
    crawlRequestIdToCompare() {
      if (!this.result || !this.result.crawlRequestIdToCompare) {
        return false;
      }
      return this.result.crawlRequestIdToCompare;
    },
    changesDetected() {
      if (!this.result || typeof this.result.countDiffTotal !== 'number') {
        return false;
      }
      return this.result.countDiffTotal;
    },
    changesDetectedOnPages() {
      if (!this.result || typeof this.result.countDiffOnPages !== 'number') {
        return false;
      }
      return this.result.countDiffOnPages;
    },
    timeCrawlStart() {
      if (!this.result || !this.result.timeCrawlStart) {
        return 'waiting for results...';
      }
      return getFormattedDate(this.result.timeCrawlStart);
    },
    VUE_APP_URL_HOME: () => process.env.VUE_APP_URL_HOME,
    crawlerConfigs() {
      return {
        dataLayerName: this.result.dataLayerName,
        ignoreHashesInUrl: this.result.ignoreHashesInUrl,
        numberOfPagesToCrawl: this.result.numberOfPagesToCrawl,
        startUrl: this.result.startUrl,
        tabsPerBrowserInstance: this.result.tabsPerBrowserInstance,
        consentConfig: this.result.consentConfig,
        waitForBeforePageEvaluate: this.result.waitForBeforePageEvaluate,
        targetCrawler: this.result.targetCrawler,
        jsToEvaluate: this.result.jsToEvaluate,
        omnibugProviderToBlock: this.result.omnibugProviderToBlock,
        comparisonIgnore: this.result.comparisonIgnore,
        onlyCrawlStartUrlSubDirs: this.result.onlyCrawlStartUrlSubDirs,
        dlPropsUseValueToCreateUniqueIdentifer: this.result.dlPropsUseValueToCreateUniqueIdentifer,
        dataLayerPropsToIgnore: this.result.dataLayerPropsToIgnore,
        crawlRequestIdToCompare: this.result.crawlRequestIdToCompare,
      };
    },
    starUrlHostName() {
      const { hostname } = new URL(this.result.startUrl);
      return hostname;
    },
  },
  methods: {
    rescan() {
      this.$emit('message', { type: 'rescan', value: 'newValue' });
    },
    setShareableSettingsApi() {
      setSharableSettings(this.crawlRequestId, this.shareableToggleTopModel);
    },
  },
};
</script>
