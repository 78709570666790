<template>
  <v-container fluid>
    <v-skeleton-loader v-if="stillLoading === true" type="image"></v-skeleton-loader>
    <v-skeleton-loader
      v-if="stillLoading === true"
      type="table-heading, table-thead, table-tbody, table-tfoot"
    ></v-skeleton-loader>

    <ReportingAccountIdsOverview
      v-bind:crawlRequestId="ucrid"
      :key="ucrid"
      v-bind:result="result"
      v-if="stillLoading === false"
      @message="messageReceiver"
    />

    <ReportingCookieScanner
      v-bind:result="result"
      v-if="stillLoading === false && result.crawledPages > 0 && result.targetCrawler === 'cookieCrawler'"
    />

    <ReportingDlMonitor
      v-bind:result="result"
      v-if="stillLoading === false && result.crawledPages > 0 && result.targetCrawler === 'dlMonitor'"
    />

    <ReportingNoPagesCrawled
      v-bind:statusMessage="{ wrap: result.statusMessage }"
      v-bind:statusMessageFormat="result.statusMessageFormat"
      v-bind:statusMessageType="result.statusMessageType"
      v-if="stillLoading === false && result.crawledPages === 0"
    />
  </v-container>
</template>

<script>
import { getLastResult, getAppTableByRequestId, sendCrawlRequest } from '../utils/apiData';
import { isValidURL } from '../utils/urls';
import ReportingAccountIdsOverview from '../components/summaryReporting/ReportingAccountIdsOverview.vue';
import ReportingCookieScanner from '../components/summaryReporting/cookieScanner/ReportingIndex.vue';
// eslint-disable-next-line import/no-unresolved
import ReportingDlMonitor from '../components/summaryReporting/dlMonitor/ReportingIndex.vue';
import ReportingNoPagesCrawled from '../components/summaryReporting/ReportingNoPagesCrawled.vue';

export default {
  name: 'SummaryReporting',
  data: () => ({
    showDialogInfo: false,
    result: {
      table: {
        trackingStats: [],
        localStorage: { rowData: [] },
        cookies: { rowData: [] },
        dataLayer: { rowData: [] },
        externalLinks: { rowData: [] },
        thirdPartyRequests: { metaData: {} },
      },
    },
    window: {
      width: 0,
      height: 0,
    },
    stillLoading: true,
  }),
  components: {
    ReportingAccountIdsOverview,
    ReportingCookieScanner,
    ReportingNoPagesCrawled,
    ReportingDlMonitor,
  },
  async mounted() {
    if (!this.$store.getters['user/loggedInStatus'] && new URLSearchParams(window.location.search).get('type') === 'private') {
      this.$router.push({ name: 'AccountLoginRegister' });
      this.$store.commit('message/setMessage', {
        color: 'error',
        message: `To see this page you have to be logged in.`,
        messageActionLabel: `Try again`,
        messageActionTarget: `${window.location}`,
      });
      return;
    }
    this.getResult();
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    showDialog() {
      const showDialog = this.$store.getters['dialog/showDialog'];
      if (showDialog === false) {
        this.$store.commit('dialog/setName', { name: 'DialogLoadingScreenExistingRequest', data: {} });
      }
    },
    showFeebackForm() {
      const messageOpen = this.$store.getters['message/showMessage'];
      if (!messageOpen) {
        this.$store.commit('message/setMessage', {
          color: 'success',
          showMessage: true,
          messageId: 'feedback',
          message: this.$t('schedulerMessageStartSuccess'),
          messageActionTarget: 'target',
          messageActionLabel: 'label',
        });
        this.$store.commit('message/setShowMessage', true);
      }
    },
    async getResult() {
      let result;

      if (this.newApp) {
        this.$store.commit('dialog/setName', {
          name: 'DialogLoadingScreen',
          data: {},
        });
      }

      if (this.ucrid) {
        this.showDialog();
        // this overlay is set by submitrequestform
        this.$emit('message', { type: 'overlay', value: '' });
        result = await getAppTableByRequestId(this.ucrid);
      } else {
        result = await getLastResult(this.hostnameToCrawl);
      }

      if (!result.success) {
        this.$store.commit('dialog/setShowDialog', false);
        if (result.data.messageId === 'COULDNOTFOUNDCRAWL') {
          this.$store.commit('message/setMessage', {
            color: 'error',
            message: `${result.data.message} Redirected you to the last allowed result of ${this.hostnameToCrawl}.`,
          });
          this.$router.push({ name: 'SummaryReporting', params: { hostnameToCrawl: this.hostnameToCrawl } });
          return;
        }

        if (result.data.messageId === 'NOCRAWLSWITHHOSTNAME') {
          this.$store.commit('message/setMessage', {
            color: 'error',
            message: `${result.data.message}`,
          });
          window.location.href = process.env.VUE_APP_URL_HOME;
          return;
        }

        this.$store.commit('message/setMessage', {
          color: 'error',
          message: `${result.data.message}`,
        });
        return;
      }

      if (result.status === 202) {
        this.$store.commit('dialog/setShowDialog', false);
        this.$store.commit('message/setMessage', {
          color: 'default',
          message: `${result.data}`,
        });
        if (this.loggedIn) {
          this.$router.push({ name: 'ReportHistoryOverviewPrivate' });
          return;
        }
        this.$router.push({ name: 'ReportHistoryOverview' });
        return;
      }

      this.result = result.data;
      this.$store.commit('summary/setScreenshotId', this.result.screenshotId);
      this.$store.commit('dialog/setShowDialog', false);

      setTimeout(() => {
        if (!localStorage.getItem('feedbackGiven') && !localStorage.getItem('feedbackNotWanted')) {
          this.showFeebackForm();
        }
      }, 8000);

      this.stillLoading = false;
    },
    async messageReceiver(message) {
      if (!message.type) {
        return;
      }
      if (message.type !== 'rescan') {
        this.$emit('message', message);
        return;
      }

      const crawlerConfigs = {
        url: this.result.startUrl,
        checkForBanner: this.result.consentConfig.checkForBanner || true,
        doTheConsentClick: this.result.consentConfig.doTheConsentClick,
        numberOfPagesToCrawl: this.result.crawledPages,
        gaEventsGroupBy: this.result.gaEventsGroupBy,
        crawlRequestIdToRepeat: this.ucrid,
      };
      const cri = await sendCrawlRequest(crawlerConfigs);
      const { hostname } = new URL(this.result.startUrl);
      if (!cri.success) {
        this.$store.commit('dialog/setShowDialog', false);
        if (cri.data.messageId === '24HDOMAINLIMITEXCEEDED') {
          this.sendError(`You have been redirected to the last result for this domain: ${cri.data.message}`);
          this.$router.push({ name: 'SummaryReporting', params: { hostnameToCrawl: hostname } });
          return;
        }
        this.sendError(`${cri.data.message}`);
        return;
      }
      this.$router.push({ name: 'SummaryReporting', params: { hostnameToCrawl: hostname }, query: { ucrid: cri.data } });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    sendError(message) {
      this.$store.commit('message/setMessage', { color: 'error', message: `${message}` });
    },
  },
  computed: {
    tabVertical() {
      if (this.window.width > 1024) {
        return true;
      }
      return false;
    },
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
    ucrid: () => new URLSearchParams(window.location.search).get('ucrid'),
    newApp: () => new URLSearchParams(window.location.search).get('newApp'),
    hostnameToCrawl: () => {
      const url = new URL(window.location);
      const paths = url.pathname.split('/');
      if ((paths.length !== 3 && paths.length !== 4) || paths[1] !== 'summary') {
        return '';
      }
      if (!isValidURL(paths[2])) {
        return false;
      }
      return paths[2];
    },
  },
  metaInfo() {
    const url = this.$store.getters['summary/getScreenshotUrl'];

    return {
      title: `Result: Banner and Cookie check for ${this.hostnameToCrawl}`,
      meta: [
        {
          name: 'description',
          content: `See cookie banner performance, cookies, localStorage and third party requests set by ${this.hostnameToCrawl}`,
        },
        {
          property: 'og:type',
          content: `Website`,
        },
        {
          property: 'og:image',
          content: url,
        },
        {
          property: 'og:title',
          content: `See cookie audit result for ${this.hostnameToCrawl}`,
        },
        {
          property: 'og:image:type',
          content: `image/png`,
        },
      ],
    };
  },
};
</script>
