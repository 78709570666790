<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="tableItems" />
</template>
<script>
import TableReporting from '../TableReporting.vue';

export default {
  name: 'ReportingMissingConsentBanner',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data: () => ({
    tableConfig: {
      sortBy: ['url'],
      sortDesc: [false],
      loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
      noDataText: 'Congrats! Banner was found on all pages. 🎉',
      headers: [
        {
          text: 'Urls with missing banner',
          value: 'url',
        },
      ],
    },
  }),
  computed: {
    tableItems() {
      if (
        !this.result ||
        !this.result.table ||
        !this.result.table.bannerNotFoundPages ||
        !this.result.table.bannerNotFoundPages.rowData
      ) {
        return [];
      }
      return this.result.table.bannerNotFoundPages.rowData;
    },
  },
  methods: {},
};
</script>
