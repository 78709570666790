<template>
  <v-card :flat="true" :tile="false">
    <v-img width="400" :src="srcScreenshotComp">
      <template v-slot:placeholder>
        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
      </template>
    </v-img>
  </v-card>
</template>
<script>
export default {
  name: 'ScreenshotImage',
  props: {},
  data: () => ({
    placeholderImages: [
      'cookie-scanner-scan-error-kitten-playing.jpg',
      'cookie-scanner-scan-error-kitten-sleeping.jpg',
      'cookie-scanner-scan-error-kitten-tree.jpg',
      'cookie-scanner-scan-error-three-kittens.jpg',
    ],
  }),
  computed: {
    srcScreenshotComp() {
      const url = this.$store.getters['summary/getScreenshotUrl'];
      if (url) {
        return url;
      }
      return `/images/${this.placeholderImages[this.getRandomInt(4)]}`;
    },
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
  },
};
</script>
