<template>
  <span>
    <v-tabs v-model="tab" centered grow icons-and-text class="mb-3" mobile-breakpoint="720">
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="1" href="#tab-1" v-if="numberOfCustomJSEval > 0">
        Custom JS Results
        <v-icon>{{ mdiCodeJson }}</v-icon>
      </v-tab>
      <v-tab key="2" href="#tab-2">
        Cookies ({{ result.numberOfCookies }})
        <v-icon>{{ mdiCookie }}</v-icon>
      </v-tab>
      <v-tab key="3" href="#tab-3">
        LocalStorage ({{ result.numberOfLocalStorage }})
        <v-icon>{{ mdiNas }}</v-icon>
      </v-tab>
      <v-tab key="4" href="#tab-4">
        Tag Inventory ({{ result.numberOfTracking }})
        <v-icon>{{ mdiFingerprint }}</v-icon>
      </v-tab>
      <v-tab key="5" href="#tab-5">
        3rd Party Request ({{ result.numberOfThirdPartyHostnames }})
        <v-icon>{{ mdiAlienOutline }}</v-icon>
      </v-tab>
      <v-tab key="6" href="#tab-6" v-if="result.table.bannerNotFoundPages.rowData.length > 0">
        Missing Banner ({{ result.table.bannerNotFoundPages.rowData.length }})
        <v-icon>{{ mdiDeathStarVariant }}</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="1" value="tab-1" v-if="numberOfCustomJSEval > 0">
        <v-card flat>
          <ReportingCustomJS v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="2" value="tab-2">
        <v-card flat>
          <TableReporting v-bind:tableConfig="tableConfigCookies" v-bind:tableItems="cookieTableData" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="3" value="tab-3">
        <v-card flat>
          <TableReporting v-bind:tableConfig="tableConfigLocalStorage" v-bind:tableItems="localStorageTableData" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="4" value="tab-4">
        <v-card flat>
          <ReportingTrackingStats v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="5" value="tab-5">
        <v-card flat>
          <ReportingThirdPartyRequest v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="6" value="tab-6" v-if="result.table.bannerNotFoundPages.rowData.length > 0">
        <v-card flat>
          <ReportingMissingConsentBanner v-bind:result="result" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </span>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiDeathStarVariant, mdiAlienOutline, mdiFingerprint, mdiNas, mdiCookie, mdiCodeJson } from '@mdi/js';
import TableReporting from '../TableReporting.vue';
import ReportingThirdPartyRequest from './ReportingThirdPartyRequest.vue';
import ReportingTrackingStats from './ReportingTrackingStats.vue';
import ReportingMissingConsentBanner from './ReportingMissingConsentBanner.vue';
import ReportingCustomJS from './ReportingCustomJS.vue';

export default {
  name: 'ReportingIndexSummary',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
    ReportingThirdPartyRequest,
    ReportingTrackingStats,
    ReportingMissingConsentBanner,
    ReportingCustomJS,
  },
  data() {
    return {
      mdiDeathStarVariant,
      mdiAlienOutline,
      mdiFingerprint,
      mdiNas,
      mdiCookie,
      mdiCodeJson,
      tab: null,
      tableConfigCookies: {
        sortBy: ['thirdParty', 'percentage', 'domain', 'name'],
        sortDesc: [true, true, false, true],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: '🧐 No cookies found.',
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
          { text: 'Domain', value: 'domain' },
          { text: 'Third-party', value: 'thirdParty' },
          {
            text: this.$t('tableHeadPagesWithEvent'),
            value: 'examplePagesFound',
          },
          {
            text: this.$t('tableHeadPagesWithMissingEvent'),
            value: 'examplePagesNotFound',
          },
          { text: this.$t('Set By'), value: 'source.type' },
          { text: this.$t('Initiator'), value: 'source.source', name: 'source' },
        ],
      },
      tableConfigLocalStorage: {
        sortBy: ['name'],
        sortDesc: [false],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: '🙈 No localStorage entries found.',
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
          { text: this.$t('tableHeadFoundOnPages'), value: 'absolute' },
          {
            text: this.$t('tableHeadPagesWithEvent'),
            value: 'examplePagesFound',
          },
          {
            text: this.$t('tableHeadPagesWithMissingEvent'),
            value: 'examplePagesNotFound',
          },
          { text: this.$t('Initiator'), value: 'source.source', name: 'source' },
        ],
      },
    };
  },
  computed: {
    localStorageTableData() {
      if (!this.result || !this.result.table || !this.result.table.localStorage || !this.result.table.localStorage.rowData) {
        return [];
      }

      return this.result.table.localStorage.rowData;
    },
    cookieTableData() {
      if (!this.result || !this.result.table || !this.result.table.cookies || !this.result.table.cookies.rowData) {
        return [];
      }

      return this.result.table.cookies.rowData;
    },
    trackingStatsTableData() {
      if (!this.result || !this.result.table || !this.result.table.trackingStats || !this.result.table.trackingStats.rowData) {
        return [];
      }
      return this.result.table.trackingStats.rowData;
    },
    numberOfCustomJSEval() {
      if (
        !this.result ||
        !this.result.table ||
        !this.result.table.customScriptResult ||
        !this.result.table.customScriptResult.rowData
      ) {
        return 0;
      }
      return this.result.table.customScriptResult.rowData.length;
    },
  },
  methods: {},
};
</script>
