<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody v-if="tableItems.length > 0">
        <tr v-for="item in tableItems" :key="item">
          <td>{{ item }}</td>
        </tr>
      </tbody>
      <tbody v-if="tableItems.length === 0">
        <tr>
          <td>no external links found.</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  name: 'RequestsReporting',
  props: {
    result: Object,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    tableItems() {
      if (this.result && this.result.table) {
        return this.result.table.externalLinks.rowData;
      }
      return [];
    },
  },
  methods: {},
};
</script>
