var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4 py-8"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5","lg":"4","xl":"4"}},[_c('ScreenshotImage')],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5","lg":"6","xl":"6"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.mainHeadline)+" "),_c('strong',[_vm._v(_vm._s(_vm.result.startUrl))]),_c('a',{attrs:{"href":_vm.result.startUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)]),_c('v-list-item-subtitle',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('reportDate'))+": "+_vm._s(_vm.timeCrawlStart)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":_vm.rescan}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiCached))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('summaryGetanewresult')))])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.secondaryHeadline)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('pagesChecked'))+": "+_vm._s(_vm.result.crawledPages)+" ")]),(_vm.crawlRequestIdToCompare && _vm.changesDetected)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('Changes detected'))+": "+_vm._s(_vm.$t('{changesDetected} on {changesDetectedOnPages} distinct pages.', { changesDetected: _vm.changesDetected, changesDetectedOnPages: _vm.changesDetectedOnPages }))+" ")]):_vm._e(),(_vm.crawlRequestIdToCompare && !_vm.changesDetected)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('Changes detected'))+": "+_vm._s(_vm.$t('none.'))+" ")]):_vm._e(),_c('v-list-item-subtitle',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('duration'))+": "+_vm._s(_vm.crawlDuration))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('bannerName'))+": "+_vm._s(_vm.bannerName)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiInformationOutline))])],1)]}}])},[_c('span',[_vm._v("Is this result not correct? Please report it, using the feedback form at the button.")])])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('bannerclicked'))+": "+_vm._s(_vm.bannerAction))]),_c('v-list-item-subtitle',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t('bannerNotFoundOnPages'))+": "+_vm._s(_vm.bannerNotFoundOnPages)+" "),(_vm.bannerNotFoundOnPages > 0)?_c('StatusIcon',{attrs:{"status":"error","statusMessage":"Is this result not correct? Please report it, using the feedback form at the button."}}):_vm._e()],1),(_vm.crawlRequestIdToCompare)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('comparedWith'))+": "),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("/summary/" + _vm.starUrlHostName + "/?ucrid=" + _vm.crawlRequestIdToCompare)}},[_vm._v(_vm._s(_vm.$t('click here for original scan result'))+" ")])]):_vm._e(),(_vm.loggedIn)?_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"pl-3",attrs:{"label":_vm.$t('Make it shareable (public)')},on:{"click":function($event){return _vm.setShareableSettingsApi()}},model:{value:(_vm.shareableToggleTopModel),callback:function ($$v) {_vm.shareableToggleTopModel=$$v},expression:"shareableToggleTopModel"}})],1):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","lg":"2","xl":"2"}},[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"width":"100%","small":_vm.$vuetify.breakpoint.mdAndDown,"href":_vm.VUE_APP_URL_HOME,"color":"primary","text":""}},[_vm._v(" "+_vm._s(_vm.$t('scananotherpage')))])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"primary",attrs:{"width":"100%","small":_vm.$vuetify.breakpoint.mdAndDown},on:{"click":function($event){return _vm.$store.commit('dialog/setName', {
                name: 'DialogScheduleATest',
                data: { crawlRequestId: _vm.crawlRequestId, crawlerConfigs: _vm.crawlerConfigs, summaryResult: _vm.result },
              })}}},[_vm._v(_vm._s(_vm.$t('createAlert')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }