<template>
  <span>
    <v-card v-if="detailsStillLoading && !keyIdParam" class="d-flex justify-center mb-6" flat tile>
      <v-progress-circular :width="10" color="primary" :size="100" indeterminate></v-progress-circular>
    </v-card>
    <TableReporting
      v-if="!detailsStillLoading && !keyIdParam"
      v-bind:tableConfig="tableConfigDetailsOverview"
      v-bind:tableItems="pagesOverview"
      :key="thisIsAComparison + countDiffTotal + crawlRequestId"
    />
    <ReportingIndexPageDetails
      :key="keyIdParam"
      v-if="keyIdParam"
      v-bind:crawlRequestId="crawlRequestId"
      v-bind:keyId="keyIdParam"
    />
  </span>
</template>

<script>
import TableReporting from '../TableReporting.vue';
import ReportingIndexPageDetails from './ReportingIndexPageDetails.vue';
import { getDetailsSummaryByRequestId } from '../../../utils/apiDetails';

export default {
  name: 'ReportingIndexPagesOverview',
  props: {
    crawlRequestId: String,
    thisIsAComparison: Boolean,
    countDiffTotal: Number,
  },
  components: {
    TableReporting,
    ReportingIndexPageDetails,
  },
  data() {
    return {
      pagesOverview: [],
      detailsStillLoading: true,
      keyIdParam: new URLSearchParams(window.location.search).get('details'),
    };
  },
  watch: {
    $route() {
      this.keyIdParam = new URLSearchParams(window.location.search).get('details');
    },
  },
  computed: {
    tableConfigDetailsOverview() {
      const base = [
        {
          text: 'Url',
          value: 'crawledUrlShort',
        },
        {
          text: 'Time',
          value: 'crawlTime',
        },
        { text: 'Banner', value: 'bannerFoundOnPage' },
        { text: 'Cookies', value: 'NoOfCookies' },
        { text: '3rd Party Req.', value: 'NoOfThirdPartyRequests' },
        { text: 'Tracking Req.', value: 'NoOfTrackingRequests' },
        { text: 'Dupe Tracking Events found', value: 'reportDupes' },
        { text: 'Ext. Links', value: 'NoOfExternalUrls' },
      ];
      if (this.thisIsAComparison) {
        base.push({ text: 'Diff', value: 'NoOfCountDiffTotal' });
      }
      base.push({ text: this.$t('tableHeadSeeDetails'), value: 'showDetailsLink', sortable: false });

      let noDataText = 'No pages Found';
      if (this.countDiffTotal === 0 && this.thisIsAComparison) {
        noDataText = `Good Job! No changes detected.`;
      }

      return {
        sortBy: ['crawlTime'],
        toggleTopShow: this.thisIsAComparison,
        toggleTopLabel: 'Show only changed pages',
        toggleFilterBy: 'NoOfCountDiffTotal',
        sortDesc: [false],
        cbRowClicked: item => {
          this.$router.push({
            name: 'SummaryReporting',
            params: { hostnameToCrawl: item.crawledHostname },
            query: { ucrid: item.crawlRequestId, details: item.keyId },
          });
        },
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText,
        headers: base,
      };
    },
  },
  async created() {
    const response = await getDetailsSummaryByRequestId(this.crawlRequestId);
    if (response.success === true) {
      this.pagesOverview = response.data;
      this.detailsStillLoading = false;
      return;
    }

    this.$store.commit('message/setMessage', { color: 'error', message: `${response.message}` });
    this.detailsStillLoading = false;
  },
  methods: {},
};
</script>
