<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="tableItems" />
</template>
<script>
import TableReporting from '../TableReporting.vue';

export default {
  name: 'ReportingThirdPartyRequest',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data() {
    return {
      tableConfig: {
        sortBy: ['absolute', 'hostname'],
        sortDesc: [true, false],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: '🦓 No 3rd party requests found.',
        headers: [
          {
            text: 'Hostname',
            value: 'hostname',
          },
          { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
          { text: this.$t('tableHeadFoundOnPages'), value: 'absolute' },
          {
            text: this.$t('tableHeadPagesWithEvent'),
            value: 'examplePagesFound',
          },
          {
            text: this.$t('tableHeadPagesWithMissingEvent'),
            value: 'examplePagesNotFound',
          },
        ],
      },
    };
  },
  computed: {
    tableItems() {
      if (this.result && this.result.table) {
        return this.result.table.thirdPartyRequests.rowData;
      }
      return [];
    },
  },
  methods: {},
};
</script>
