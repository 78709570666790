<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="getComputedRowData()" />
</template>
<script>
import TableReporting from '../../TableReporting.vue';

export default {
  name: 'RequestsReporting',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data() {
    return {
      tableConfig: {
        sortBy: ['absolute'],
        sortDesc: [true],
        itemKey: 'hostname',
        showGroupBy: true,
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: 'Tracking.',
        headers: [
          {
            text: 'Hostname',
            value: 'hostname',
          },
          { text: this.$t('path'), value: 'path' },
          { text: this.$t('parameters'), value: 'searchString' },
          { text: this.$t('No. of Request'), value: 'count' },
          { text: '', value: 'data-table-expand' },
        ],
      },
    };
  },
  computed: {
    tableItems() {
      if (this.result && this.result.table) {
        return this.result.table.thirdPartyRequests.rowData;
      }
      return [];
    },
  },
  methods: {
    computedCallTree(callTree) {
      if (!Array.isArray(callTree)) {
        return [this.$t('Initiator could not be found')];
      }
      const lastTwo = callTree.slice(-2);
      return lastTwo.map(initObj => `${initObj.initiatorUrl}:${initObj.lineNumber}`);
    },
    getComputedRowData() {
      const { tableItems } = this;
      for (let i = 0; i < tableItems.length; i += 1) {
        tableItems[i].expandArea = this.computedCallTree(tableItems[i].callTree);
        tableItems[i].id = `${tableItems[i].hostname}${tableItems[i].path}${tableItems[i].searchString}`;
      }
      return tableItems;
    },
  },
};
</script>
