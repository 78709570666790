import { doGet, getApiUrl } from './api';

const baseUrl = getApiUrl();

async function getDetailsSummaryByRequestId(crawlRequestId) {
  const response = await doGet(`${baseUrl}v2/crawlingresult/crawlrequestid/${crawlRequestId}/apptable/details/`);
  return response;
}

async function getCompareAffectedDetailsSummaryByRequestId(crawlRequestId) {
  const response = await doGet(`${baseUrl}v2/crawlingresult/crawlrequestid/${crawlRequestId}/comparison/result/affectedPages`);
  return response;
}

async function getPageDetails(crawlRequestId, keyId) {
  const response = await doGet(`${baseUrl}v2/crawlingresult/crawlrequestid/${crawlRequestId}/apptable/details/${keyId}`);
  return response;
}

export { getDetailsSummaryByRequestId, getPageDetails, getCompareAffectedDetailsSummaryByRequestId };
