<template>
  <div>
    <v-tooltip top v-if="statusIcon">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" v-if="statusIcon" medium :color="`${color} darken-2`">
          {{ statusIcon }}
        </v-icon>
      </template>
      <span v-if="statusMessage">{{ statusMessage }}</span>
    </v-tooltip>

    <v-progress-circular v-if="!statusIcon" :size="15" color="primary" width="1" indeterminate></v-progress-circular>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiCheck, mdiAlertOctagramOutline, mdiAlert, mdiHelpCircle } from '@mdi/js';

export default {
  name: 'StatusIcon',
  props: {
    status: String,
    statusMessage: String,
    statusMessageType: String,
  },
  data: () => ({ color: 'green' }),
  computed: {
    statusIcon() {
      if (this.status === 'start') {
        return false;
      }

      if (this.status === 'success' || typeof this.status === 'undefined') {
        return mdiCheck;
      }

      if (this.status === 'error' && this.statusMessageType === 'warning') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.color = 'yellow';
        return mdiAlertOctagramOutline;
      }
      if (this.status === 'error') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.color = 'red';
        return mdiAlert;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.color = 'default';
      return mdiHelpCircle;
    },
  },
};
</script>
