<template>
  <span>
    <v-card class="px-4 py-8" :tile="false">
      <v-tabs v-model="tab" centered grow icons-and-text class="mb-3" mobile-breakpoint="720">
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="1" href="#tab-1">
          Summary
          <v-icon>{{ mdiCodeJson }}</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="1" value="tab-1">
          <v-card flat>
            <ReportingDataLayer v-bind:result="result" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </span>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiDeathStarVariant, mdiAlienOutline, mdiFingerprint, mdiNas, mdiCookie, mdiCodeJson } from '@mdi/js';
import ReportingDataLayer from './ReportingDataLayer.vue';

export default {
  name: 'ReportingDlMonitor',
  props: {
    result: Object,
  },
  components: {
    ReportingDataLayer,
  },
  data() {
    return {
      mdiDeathStarVariant,
      mdiAlienOutline,
      mdiFingerprint,
      mdiNas,
      mdiCookie,
      mdiCodeJson,
      tab: null,
    };
  },
  computed: {},
  methods: {},
};
</script>
