<template>
  <span>
    <ResultEvaluation v-if="!thisIsAComparison" v-bind:result="result" class="mt-6" @message="messageReceiver" />
    <v-card class="px-4 py-8 mt-6" :tile="false">
      <v-btn
        v-if="showDepth === 'pages' && !thisIsAComparison"
        small
        @click="setSummary()"
        color="primary"
        class="float-md-right"
        elevation="0"
      >
        {{ buttonTextGoToSummary }}</v-btn
      >
      <v-btn
        v-if="showDepth === 'summary' && !thisIsAComparison"
        small
        @click="setPages()"
        color="blue-grey"
        class="float-md-right white--text"
        elevation="0"
      >
        {{ buttonTextGoToPages }}</v-btn
      >
      <v-radio-group v-model="showDepth" row v-if="!thisIsAComparison">
        <v-radio label="Summary" value="summary" @click="setSummary()"></v-radio>
        <v-radio label="Pages" value="pages" @click="setPages()"></v-radio>
      </v-radio-group>
      <ReportingIndexSummary v-bind:result="result" v-if="showDepth === 'summary' && !thisIsAComparison" />
      <ReportingIndexPagesOverview
        v-bind:crawlRequestId="crawlRequestId"
        v-bind:thisIsAComparison="thisIsAComparison"
        v-bind:countDiffTotal="countDiffTotal || 0"
        v-if="showDepth === 'pages' || thisIsAComparison"
        :key="thisIsAComparison + countDiffTotal + crawlRequestId"
      />
    </v-card>
  </span>
</template>

<script>
import ResultEvaluation from './ResultEvaluation.vue';
import ReportingIndexSummary from './ReportingIndexSummary.vue';
import ReportingIndexPagesOverview from './ReportingIndexPagesOverview.vue';

export default {
  name: 'ReportingCookieScanner',
  props: {
    result: Object,
  },
  components: {
    ResultEvaluation,
    ReportingIndexSummary,
    ReportingIndexPagesOverview,
  },
  data() {
    return {
      showDepth: 'summary',
      buttonTextGoToSummary: 'go to summary',
      buttonTextGoToPages: 'go to page details',
      tab: null,
      tableConfigCookies: {
        sortBy: ['thirdParty', 'percentage', 'domain', 'name'],
        sortDesc: [true, true, false, true],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: 'No Cookies found..',
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
          { text: 'Domain', value: 'domain' },
          { text: 'Third-party', value: 'thirdParty' },
          {
            text: this.$t('tableHeadPagesWithEvent'),
            value: 'examplePagesFound',
          },
          {
            text: this.$t('tableHeadPagesWithMissingEvent'),
            value: 'examplePagesNotFound',
          },
          { text: this.$t('Set By'), value: 'source.type' },
          { text: this.$t('Initiator'), value: 'source.source', name: 'source' },
        ],
      },
      tableConfigLocalStorage: {
        sortBy: ['name'],
        sortDesc: [false],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: 'No localStorage Entries found.',
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
          { text: this.$t('tableHeadFoundOnPages'), value: 'absolute' },
          {
            text: this.$t('tableHeadPagesWithEvent'),
            value: 'examplePagesFound',
          },
          {
            text: this.$t('tableHeadPagesWithMissingEvent'),
            value: 'examplePagesNotFound',
          },
          { text: this.$t('Initiator'), value: 'source.source', name: 'source' },
        ],
      },
    };
  },
  async created() {
    if (new URLSearchParams(window.location.search).get('pages') || new URLSearchParams(window.location.search).get('details')) {
      this.showDepth = 'pages';
      return;
    }
    this.showDepth = 'summary';
  },
  computed: {
    thisIsAComparison() {
      if (!this.result || !this.result.crawlRequestIdToCompare) {
        return false;
      }
      return true;
    },
    countDiffTotal() {
      if (!this.result || typeof this.result.countDiffTotal !== 'number') {
        return false;
      }
      return this.result.countDiffTotal;
    },
    crawlRequestId() {
      if (!this.result || !this.result.crawlRequestId) {
        return '';
      }
      return this.result.crawlRequestId;
    },
    localStorageTableData() {
      if (!this.result || !this.result.table || !this.result.table.localStorage.rowData) {
        return [];
      }

      return this.result.table.localStorage.rowData;
    },
    cookieTableData() {
      if (!this.result || !this.result.table || !this.result.table.cookies.rowData) {
        return [];
      }

      return this.result.table.cookies.rowData;
    },
    numberOfCustomJSEval() {
      if (
        !this.result ||
        !this.result.table ||
        !this.result.table.customScriptResult ||
        !this.result.table.customScriptResult.rowData
      ) {
        return 0;
      }
      return this.result.table.customScriptResult.rowData.length;
    },
  },
  methods: {
    setSummary() {
      this.showDepth = 'summary';
      this.buttonText = this.buttonTextGoToPages;
      this.$router.push({
        name: 'SummaryReporting',
        params: { hostnameToCrawl: this.result.startHostname },
        query: { ucrid: this.crawlRequestId, summary: true },
      });
    },
    messageReceiver(message) {
      if (message.type && message.type === 'setSummary') {
        this.setSummary();
      }

      if (message.type && message.type === 'navDrawer') {
        this.drawer = message.value;
      }
    },
    setPages() {
      this.showDepth = 'pages';
      this.buttonText = this.buttonTextGoToSummary;
      this.$router.push({
        name: 'SummaryReporting',
        params: { hostnameToCrawl: this.result.startHostname },
        query: { ucrid: this.crawlRequestId, pages: true },
      });
    },
  },
};
</script>
