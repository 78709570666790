<template>
  <v-data-table
    dense
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :loading-text="loadingText"
    :headers="internalHeaders"
    :items="internalTableItems"
    :items-per-page="itemsPerPage"
    :no-data-text="noDataText"
    :show-group-by="tableConfig.showGroupBy"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :show-expand="showExpand"
    item-key="id"
    class="elevation-0"
    :footer-props="{
      itemsPerPageOptions: [50, 100, -1],
    }"
    :item-class="itemRowBackground"
  >
    <template v-slot:top v-if="tableConfig.toggleTopShow">
      <v-switch v-model="toggleTopModel" :label="tableConfig.toggleTopLabel" class="pa-3"></v-switch>
    </template>

    <template v-slot:item.data-table-expand="{ item, isExpanded }">
      <a @click="handleExpansion(item, isExpanded)" v-if="isExpanded" class="text-decoration-underline">close</a>
      <a @click="handleExpansion(item, isExpanded)" v-if="!isExpanded" class="text-decoration-underline">initiator</a>
    </template>

    <template v-slot:item.value="{ item }">
      <span @click="rowClicked(item.value)">{{ shortenValue(item.value) }}</span>
      <v-tooltip top v-if="item.value && item.value.length > maxValueLength">
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="copyToClipboard(item.value)" v-bind="attrs" v-on="on" dense>{{ mdiContentCopy }}</v-icon>
        </template>
        <span>{{ item.value }}</span>
      </v-tooltip></template
    >

    <template v-slot:item.name="{ item }">
      <span @click="rowClicked(item.name)">{{ shortenValue(item.name) }}</span>
      <v-tooltip top v-if="item.name && item.name.length > maxValueLength">
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="copyToClipboard(item.name)" v-bind="attrs" v-on="on" dense>{{ mdiContentCopy }}</v-icon>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip></template
    >

    <template v-slot:item.accountId="{ item }">
      <span @click="rowClicked(item.accountId)">{{ shortenValue(item.accountId) }}</span>
      <v-tooltip top v-if="item.accountId && item.accountId.length > maxValueLength">
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="copyToClipboard(item.accountId)" v-bind="attrs" v-on="on" dense>{{ mdiContentCopy }}</v-icon>
        </template>
        <span>{{ item.accountId }}</span>
      </v-tooltip></template
    >

    <template v-slot:item.searchString="{ item }">
      <span @click="rowClicked(item.searchString)">{{ shortenValue(item.searchString) }}</span>
      <v-tooltip top v-if="item.searchString && item.searchString.length > maxValueLength">
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="copyToClipboard(item.searchString)" v-bind="attrs" v-on="on" dense>{{ mdiContentCopy }}</v-icon>
        </template>
        <span>{{ item.searchString }}</span>
      </v-tooltip></template
    >

    <template v-slot:item.path="{ item }">
      <span @click="rowClicked(item.path)">{{ shortenValue(item.path) }}</span>
      <v-tooltip top v-if="item.path && item.path.length > maxValueLength">
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="copyToClipboard(item.path)" v-bind="attrs" v-on="on" dense>{{ mdiContentCopy }}</v-icon>
        </template>
        <span>{{ item.path }}</span>
      </v-tooltip></template
    >

    <template v-slot:item.examplePagesNotFound="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-for="(link, index) in item.examplePagesNotFound" :key="index">
            <a class="grey--text text--darken-1" :href="link" target="_blank">{{ getDisplayLink(link) }}</a></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:item.examplePagesFoundDuplicates="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-for="(link, index) in item.examplePagesFoundDuplicates" :key="index">
            <a class="grey--text text--darken-1" :href="link" target="_blank">{{ getDisplayLink(link) }}</a></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:item.examplePagesFound="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-for="(link, index) in item.examplePagesFound" :key="index">
            <a class="grey--text text--darken-1" :href="link" target="_blank">{{ getDisplayLink(link) }}</a></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:item.timeCrawlStart="{ item }">
      {{ getFormattedDate(item.timeCrawlStart) }}
    </template>

    <template v-slot:item.crawlTime="{ item }">
      {{ getFormattedDate(item.crawlTime) }}
    </template>

    <template v-slot:item.crawledUrlShort="{ item }">
      <td>
        {{ item.crawledUrlShort }}
        <a :href="item['crawledUrl']" target="_blank"
          ><v-icon dense>{{ mdiOpenInNew }}</v-icon></a
        >
      </td>
    </template>

    <template v-slot:item.showDetailsLink="{ item }">
      <td v-if="item.NoOfCountDiffTotal > 0 || !tableConfig.toggleTopShow" @click="rowClicked(item)">
        <a>show details</a>
      </td>
      <td v-if="item.NoOfCountDiffTotal === 0 && tableConfig.toggleTopShow">n.a.</td>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-for="(link, index) in item.expandArea" :key="index"> {{ link }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </td>
    </template>
  </v-data-table>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiOpenInNew, mdiContentCopy } from '@mdi/js';
import { getLinkForDisplay } from '../../utils/urls';
import { getRowColor } from '../../utils/helpers';
import { getFormattedDate } from '../../utils/date';

export default {
  name: 'TableReporting',
  props: {
    tableConfig: Object,
    tableItems: Array,
  },
  data: () => ({
    toggleTopModel: true,
    itemsPerPage: 50,
    mdiOpenInNew,
    mdiContentCopy,
    maxValueLength: 25,
    expanded: [],
    singleExpand: true,
    headerDefaultConfig: {
      align: 'start',
      sortable: true,
      filterable: true,
      groupable: false,
      divider: false,
    },
  }),
  computed: {
    internalHeaders() {
      const newHeader = [];
      for (let i = 0; i < this.tableConfig.headers.length; i += 1) {
        newHeader.push({ ...this.headerDefaultConfig, ...this.tableConfig.headers[i] });
      }
      return newHeader;
    },
    sortBy() {
      return this.tableConfig.sortBy;
    },
    showExpand() {
      if (this.tableItems[0] && this.tableItems[0].expandArea) {
        return true;
      }
      return false;
    },
    sortDesc() {
      return this.tableConfig.sortDesc;
    },
    loadingText() {
      return this.tableConfig.loadingText;
    },
    noDataText() {
      return this.tableConfig.noDataText;
    },
    locale() {
      const userLang = navigator.language || navigator.userLanguage;
      return userLang;
    },
    internalTableItems() {
      if (!this.tableConfig.toggleTopShow) {
        return this.tableItems;
      }

      if (this.toggleTopModel === false) {
        return this.tableItems;
      }

      return this.tableItems.filter(row => {
        if (row[`${this.tableConfig.toggleFilterBy}`]) {
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
    },
    getDisplayLink(link) {
      return getLinkForDisplay(link);
    },
    getColorForRow(testStatus) {
      return getRowColor(testStatus);
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    itemRowBackground(item) {
      if (item.testStatus === 'add') {
        return 'compare-add';
      }

      if (item.testStatus === 'delete') {
        return 'compare-delete';
      }

      if (item.testStatus === 'update') {
        return 'compare-update';
      }

      return '';
    },
    shortenValue(value) {
      if (value && value.length > this.maxValueLength) {
        return `${value.substring(0, this.maxValueLength)}...`;
      }
      return value;
    },
    rowClicked(item) {
      if (this.tableConfig.cbRowClicked) {
        this.tableConfig.cbRowClicked(item);
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);

      if (state) {
        this.expanded.splice(itemIndex, 1);
      } else {
        this.expanded.push(item);
      }
    },
  },
};
</script>
<style>
.tooltip-no-opacity {
  opacity: 1 !important;
}
tr.compare-add {
  background-color: rgba(61, 149, 65, 0.62) !important;
}
tr.compare-delete {
  background-color: rgba(249, 175, 175, 0.686) !important;
}
tr.compare-update {
  background-color: rgba(236, 231, 140, 0.798) !important;
}
</style>
