<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="tableItems" />
</template>
<script>
import TableReporting from '../TableReporting.vue';

export default {
  name: 'ReportingTrackingStats',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data: () => ({
    tableConfigRaw: {
      sortBy: [],
      sortDesc: [],
      loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
      noDataText: '😎 No tracking tags found.',
    },
  }),
  computed: {
    tableConfig() {
      const newTc = this.tableConfigRaw;
      newTc.headers = this.headers;
      return newTc;
    },
    headers() {
      let waitTime = '..';
      if (this.result && this.result.waitForBeforePageEvaluate) {
        waitTime = this.result.waitForBeforePageEvaluate;
      }
      const headers = [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: this.$t('tableHeadEventType'),
          value: 'requestType',
        },
        {
          text: this.$t('accountId'),
          value: 'accountId',
        },
        { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
        { text: `${this.$t('tableHeadFoundOnPagesWithWaitingTime')} ${waitTime}ms`, value: 'absolute' },
        { text: `${this.$t('Pages with dupes')}`, value: 'reportDupes' },
        { text: `${this.$t('Google Consent Mode')}`, value: 'consentMode' },
        { text: `${this.$t('Hostname')}`, value: 'hostname' },
        {
          text: this.$t('tableHeadPagesWithEvent'),
          value: 'examplePagesFound',
        },
        {
          text: this.$t('tableHeadPagesWithMissingEvent'),
          value: 'examplePagesNotFound',
        },
      ];
      return headers;
    },
    tableItems() {
      if (this.result && this.result.table) {
        return this.result.table.trackingStats.rowData;
      }
      return [];
    },
  },
  methods: {},
};
</script>
