<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="tableItems" />
</template>
<script>
import TableReporting from '../TableReporting.vue';

export default {
  name: 'ReportingDataLayer',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data() {
    return {
      tableConfig: {
        sortBy: ['eventName', 'absolute'],
        sortDesc: [false, true],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: 'No dataLayer pushes found.',
        headers: [
          {
            text: 'Event Name',
            value: 'eventName',
          },
          { text: this.$t('tableHeadFoundOnPages'), value: 'absolute' },
          { text: this.$t('tableHeadFoundOnPagesPercent'), value: 'percentage' },
          {
            text: this.$t('tableHeadExampleValue'),
            value: 'dataLayerExample',
          },
          {
            text: this.$t('tableHeadPagesDupe'),
            value: 'pagesWithDuplicateIdentiferAbsolute',
          },
          {
            text: this.$t('tableHeadPagesWithEvent'),
            value: 'examplePagesFound',
          },
          {
            text: this.$t('tableHeadPagesWithMissingEvent'),
            value: 'examplePagesNotFound',
          },
          {
            text: this.$t('tableHeadPagesWithDuplicateEvent'),
            value: 'examplePagesFoundDuplicates',
          },
        ],
      },
    };
  },
  computed: {
    tableItems() {
      if (!this.result || !this.result.table || !this.result.table.dataLayerStats || !this.result.table.dataLayerStats.rowData) {
        return [];
      }

      return this.result.table.dataLayerStats.rowData;
    },
  },
  methods: {},
};
</script>
