<template>
  <TableReporting v-bind:tableConfig="tableConfig" v-bind:tableItems="tableItems" />
</template>
<script>
import TableReporting from '../../TableReporting.vue';

export default {
  name: 'CookieReporting',
  props: {
    result: Object,
  },
  components: {
    TableReporting,
  },
  data() {
    return {
      tableConfig: {
        sortBy: ['thirdParty', 'percentage', 'domain', 'name'],
        sortDesc: [true, true, false, true],
        loadingText: 'A good page crawl needs a couple of minutes. Please be patient.',
        noDataText: 'No Cookies found..',
        showGroupBy: true,
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          { text: this.$t('Domain'), value: 'domain' },
          { text: this.$t('thirdParty'), value: 'thirdParty' },
          { text: this.$t('sameSite'), value: 'sameSite' },
          { text: this.$t('httpOnly'), value: 'httpOnly' },
          { text: this.$t('Expires'), value: 'expirationInDays' },
          { text: this.$t('Set By'), value: 'source.type' },
          { text: this.$t('Source'), value: 'source.source', name: 'source' },
          { text: this.$t('Value'), value: 'value' },
        ],
      },
    };
  },
  computed: {
    tableItems() {
      if (this.result && this.result.table) {
        return this.result.table.cookies.rowData;
      }
      return [];
    },
  },
  methods: {
    // getComputedRowData() {
    //   const { tableItems } = this;
    //   for (let i = 0; i < tableItems.length; i += 1) {
    //     if (!tableItems[i].source) {
    //       tableItems[i].serverCookie = 'n.a.';
    //       tableItems[i].cookieSource = 'n.a.';
    //       continue;
    //     }
    //     if (tableItems[i].source.type) {
    //       tableItems[i].serverCookie = 'n.a.';
    //       tableItems[i].source = 'n.a.';
    //       continue;
    //     }
    //     tableItems[i].source = this.computedCallTree(tableItems[i].sourc);
    //     tableItems[i].id = `${tableItems[i].hostname}${tableItems[i].path}${tableItems[i].searchString}`;
    //   }
    //   return tableItems;
    // },
  },
};
</script>
