const configs = [
  {
    id: 'wpbeautifulcookiebanner',
    name: 'Beautiful Cookie Banner (WP-Plugin)',
  },
  {
    id: 'orestbidacookieconsent2',
    name: 'Orestbida 2.xx',
  },
  {
    id: 'pressidium',
    name: 'Orestbida 2.xx',
  },
  {
    id: 'salesforceDemandWare',
    name: 'Salesforce Demandware',
  },
  {
    id: 'usercentrics',
    name: 'Usercentrics',
  },
  {
    id: 'cookiebot',
    name: 'Cookiebot',
  },
  {
    id: 'cookieproonetrust',
    name: 'Onetrust (Cookie Pro and others)',
  },
  {
    id: 'wix',
    name: 'Wix Cookie Banner',
  },
  {
    id: 'conentmanagerde',
    name: 'consentmanager.net/de',
  },
  {
    id: 'cookieinformationcom',
    name: 'cookieinformation.com',
  },
  {
    id: 'cookiescriptcom',
    name: 'Cookie Script',
  },
  {
    id: 'klaro',
    name: 'Klaro! Banner',
  },
  {
    id: 'osano',
    name: 'Osano Banner',
  },
  {
    id: 'wpcomplianz',
    name: 'Complianz Cookie Consent (WP Plugin)',
  },
  {
    id: 'iubenda',
    name: 'iubenda',
  },
  {
    id: 'wpeucookielaw',
    name: 'EU Cookie Law (WP Plugin)',
  },
  {
    id: 'cookiecompliancehumanity',
    name: 'Cookie Compliance by Hu-manity.co',
  },
  {
    id: 'wpgdprcompliance',
    name: 'GDPR Compliance by stylemixthemes (WP Plugin)',
  },
  {
    id: 'wpgdprcookieconsentwebtoffee',
    name: 'GDPR Cookie Consent by webtoffee (WP Plugin)',
  },
  {
    id: 'wpgdprcookiecompliancemoove',
    name: 'GDPR Cookie Compliance by Moove Agency (WP Plugin)',
  },
  {
    id: 'didomicmp',
    name: 'Didomi CMP',
  },
  {
    id: 'enzuzo',
    name: 'enzuzo CMP',
  },
  {
    id: 'borlabscookie',
    name: 'Borlabs (WP Plugin)',
  },
  {
    id: 'termly',
    name: 'Termly Cookie Banner',
  },
  {
    id: 'ccm19',
    name: 'CCM19 - CMP',
  },
  {
    id: 'ketch',
    name: 'ketch CMP',
  },
  {
    id: 'consentstudio',
    name: 'Consent Studio',
  },
  {
    id: 'cookieyes',
    name: 'cookieYes',
  },
  {
    id: 'opxcookieconsent',
    name: 'OPX Cookie Consent',
  },
  {
    id: 'gambiocmp',
    name: 'Gambio Consent Banner (oil)',
  },
  {
    id: 'shopifycmp',
    name: 'Shopify CMP',
  },
  {
    id: 'piwikbanner',
    name: 'Piwik Banner',
  },
  {
    id: 'trustarc',
    name: 'TrustArc',
  },
  {
    id: 'hubspotprivacy',
    name: 'HubSpot privacy banner',
  },
  {
    id: 'cookiefirst',
    name: 'Cookiefirst',
  },
  {
    id: 'squarespacecookiebanner',
    name: 'Squarespace Cookie Banner',
  },
  {
    id: 'privacywire',
    name: 'PrivacyWire',
  },
  {
    id: 'transcendprivacy',
    name: 'Transcend CMP',
  },
  {
    id: 'unknownbanner',
    name: 'Unknown Banner Provider',
  },
];

function getBannerNameById(id) {
  for (let i = 0; i < configs.length; i += 1) {
    if (configs[i].id === id) {
      return configs[i].name;
    }
  }
  // eslint-disable-next-line no-console
  console.error(`name for banner id "${id}" not found`);
  return id || 'no banner found';
}

export default getBannerNameById;
