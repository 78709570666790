<template>
  <span>
    <v-tabs v-model="tab" centered grow icons-and-text class="mb-3" mobile-breakpoint="720">
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        key="1"
        href="#tab-1"
        v-if="numberOfCustomJSEval > 0 && (typesToCompare.length === 0 || typesToCompare.includes('customScriptResult'))"
      >
        Custom JS Results
        <v-icon>{{ mdiCodeJson }}</v-icon>
      </v-tab>
      <v-tab key="2" href="#tab-2" v-if="typesToCompare.length === 0 || typesToCompare.includes('cookies')">
        All Cookies ({{ result.countCookies }})
        <v-icon>{{ mdiCookie }}</v-icon>
      </v-tab>
      <v-tab key="3" href="#tab-3" v-if="typesToCompare.length === 0 || typesToCompare.includes('localStorage')">
        LocalStorage ({{ result.countLocalStorage }})
        <v-icon>{{ mdiNas }}</v-icon>
      </v-tab>
      <v-tab key="4" href="#tab-4" v-if="typesToCompare.length === 0 || typesToCompare.includes('trackingRequests')">
        Tag Inventory ({{ result.countTrackingRequest }})
        <v-icon>{{ mdiFingerprint }}</v-icon>
      </v-tab>
      <v-tab key="5" href="#tab-5" v-if="typesToCompare.length === 0 || typesToCompare.includes('thirdPartyRequests')">
        3rd Party Request ({{ result.countThirdpartyRequests }})
        <v-icon>{{ mdiAlienOutline }}</v-icon>
      </v-tab>
      <v-tab key="6" href="#tab-6" v-if="typesToCompare.length === 0 || typesToCompare.includes('externalLinks')">
        Ext. Links ({{ result.countExternalLinks }})
        <v-icon>{{ mdiLaserPointer }}</v-icon>
      </v-tab>
      <v-tab key="7" href="#tab-7" v-if="result.table.bannerNotFoundPages.rowData.length > 0">
        Missing Banner ({{ result.table.bannerNotFoundPages.rowData.length }})
        <v-icon>{{ mdiDeathStarVariant }}</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="1" value="tab-1" v-if="numberOfCustomJSEval > 0">
        <v-card flat>
          <CookieReporting v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="2" value="tab-2">
        <v-card flat>
          <CookieReporting v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="3" value="tab-3">
        <v-card flat>
          <LocalStorageReporting v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="4" value="tab-4">
        <v-card flat>
          <TrackingReporting v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="5" value="tab-5">
        <v-card flat>
          <RequestsReporting v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="6" value="tab-6">
        <v-card flat>
          <ExternalLinksReporting v-bind:result="result" />
        </v-card>
      </v-tab-item>
      <v-tab-item key="7" value="tab-7" v-if="result.table.bannerNotFoundPages.rowData.length > 0">
        <v-card flat> </v-card>
      </v-tab-item>
    </v-tabs-items>
  </span>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiDeathStarVariant, mdiAlienOutline, mdiFingerprint, mdiNas, mdiCookie, mdiCodeJson, mdiLaserPointer } from '@mdi/js';
import RequestsReporting from './RequestsReporting.vue';
import TrackingReporting from './TrackingReporting.vue';
import CookieReporting from './CookieReporting.vue';
import LocalStorageReporting from './LocalStorageReporting.vue';
import ExternalLinksReporting from './ExternalLinksReporting.vue';

export default {
  name: 'OverviewReporting',
  props: {
    result: Object,
  },
  components: {
    RequestsReporting,
    TrackingReporting,
    CookieReporting,
    LocalStorageReporting,
    ExternalLinksReporting,
  },
  data() {
    return {
      mdiDeathStarVariant,
      mdiLaserPointer,
      mdiAlienOutline,
      mdiFingerprint,
      mdiNas,
      mdiCookie,
      mdiCodeJson,
      tab: null,
    };
  },
  computed: {
    typesToCompare() {
      if (!this.result || !this.result.schedulerConfig || !this.result.schedulerConfig.typesToCompare) {
        return [];
      }
      return this.result.schedulerConfig.typesToCompare;
    },
    localStorageTableData() {
      if (!this.result || !this.result.table || !this.result.table.localStorage || !this.result.table.localStorage.rowData) {
        return [];
      }

      return this.result.table.localStorage.rowData;
    },
    cookieTableData() {
      if (!this.result || !this.result.table || !this.result.table.cookies || !this.result.table.cookies.rowData) {
        return [];
      }

      return this.result.table.cookies.rowData;
    },
    trackingStatsTableData() {
      if (!this.result || !this.result.table || !this.result.table.trackingStats || !this.result.table.trackingStats.rowData) {
        return [];
      }
      return this.result.table.trackingStats.rowData;
    },
    numberOfCustomJSEval() {
      if (
        !this.result ||
        !this.result.table ||
        !this.result.table.customScriptResult ||
        !this.result.table.customScriptResult.rowData
      ) {
        return 0;
      }
      return this.result.table.customScriptResult.rowData.length;
    },
  },
  methods: {},
};
</script>
